
import {computed, defineComponent, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {IonContent, IonPage, IonSpinner} from "@ionic/vue";

export default defineComponent({
  name: "Loading",
  components: {
    IonPage,
    IonContent,
    IonSpinner
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const fullPath: any = route.params.fullPath;
    const isRestored = computed(() => store.getters.isRestored);

    watch(isRestored, () => {
      if (isRestored.value) {
        router.push({path: fullPath});
      }
    });

    if (isRestored.value) {
      router.push({path: fullPath});
    }

    return {};
  }
})
